import React, { useRef } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

import {
  useGetStoreByIdQuery,
  useActivateStoreMutation,
  useEnableStoreMutation,
  useDisableStoreMutation,
} from "../../state/slices/storesApiSlice";

import Loader from "../../components/common/loaders/Loader";
import Message from "../../components/common/Message";

import MainContent from "../../components/layouts/MainContent";
import ContentHeader from "../../components/layouts/ContentHeader";
import Modal from "../../components/common/Modal";
import ModalHeader from "../../components/common/ModalHeader";
import ModalBody from "../../components/common/ModalBody";
import ModalFooter from "../../components/common/ModalFooter";

import imagePlaceholder from "../../assets/profile_placeholder.jpg";
import { formatIsoDate } from "../../utils/utils";
import { useSelector } from "react-redux";
import { selectUserInfo } from "../../state/slices/authSlice";
import { toast } from "react-toastify";

const ShopperGroupDetailPage = () => {
  const { id } = useParams();
  const approveStoreRef = useRef();
  const enableStoreRef = useRef();
  const disableStoreRef = useRef();

  const { id: adminId, accessToken } = useSelector(selectUserInfo);

  const { data, isLoading, error, refetch } = useGetStoreByIdQuery({
    storeId: id,
    accessToken,
  });

  const [activateStore, { isLoading: isActivating }] =
    useActivateStoreMutation();

  const [enableStore, { isLoading: isEnabling }] = useEnableStoreMutation();

  const [disableStore, { isLoading: isDisabling }] = useDisableStoreMutation();

  const {
    locationDetails,
    name,
    description,
    merchant,
    isApproved,
    isEnabled,
    approvedBy,
    approvedAt,
    storeType,
    palengke,
    storePhotoUrl,
    createdAt,
    updatedAt,
  } = data || {};

  const handleApproveStore = async () => {
    try {
      await activateStore({
        storeId: id,
        adminId,
        accessToken,
      }).unwrap();

      approveStoreRef.current.click();

      refetch();

      toast.success("Successfully approved store.");
    } catch (error) {
      toast.error("Unable to approve store.");
    }
  };

  const handleEnableStore = async () => {
    try {
      await enableStore({
        storeId: id,
        accessToken,
      }).unwrap();

      enableStoreRef.current.click();

      refetch();

      toast.success("Successfully enable store.");
    } catch (error) {
      toast.error("Failed to enable store.");
    }
  };

  const handleDisableStore = async () => {
    try {
      await disableStore({
        storeId: id,
        accessToken,
      }).unwrap();

      disableStoreRef.current.click();

      refetch();

      toast.success("Successfully disabled store.");
    } catch (error) {
      console.log(error);

      toast.error("Failed to disable store.");
    }
  };

  return (
    <>
      <ContentHeader pageTitle='Store Details'>
        <ol className='breadcrumb'>
          <li>
            <Link to='/stores'>
              <i className='fa fa-list'></i> Stores
            </Link>
          </li>
          <li>
            <Link to='#'>
              <i className='fa fa-info'></i> Store Details
            </Link>
          </li>
        </ol>
      </ContentHeader>
      <MainContent>
        <div className='row'>
          <div className='col-md-6'>
            <div className='box box-solid'>
              <div className='box-body'>
                <div className='box box-solid box-widget widget-user-2'>
                  <div className='box-header with-border'>
                    <h3 className='box-title'>
                      <i className='fa fa-info'></i> {name?.toUpperCase()}{" "}
                      &mdash; {id}
                    </h3>
                  </div>
                  <div
                    className='box-body'
                    style={{ fontSize: "1.5rem" }}
                  >
                    {isLoading && (
                      <Loader
                        title='Loading...'
                        size={50}
                        center
                      />
                    )}

                    {error && (
                      <Message
                        variant='danger'
                        message={
                          error?.data?.message ||
                          error?.error ||
                          "Something went wrong."
                        }
                      />
                    )}

                    {!isLoading && !error && (
                      <>
                        <div className='widget-user-header bg-red'>
                          <div className='widget-user-image'>
                            <img
                              className='img-circle'
                              style={{
                                width: "60px",
                                height: "60px",
                                borderRadius: "50%",
                                objectFit: "cover",
                              }}
                              src={
                                storePhotoUrl ? storePhotoUrl : imagePlaceholder
                              }
                              alt='User Avatar'
                            />
                          </div>
                          <h3 className='widget-user-username'>{name}</h3>
                          <h5 className='widget-user-desc'>{storeType}</h5>
                        </div>
                        <ul className='list-group list-group-flush'>
                          <li className='list-group-item'>
                            ID:
                            <span className='pull-right'>{id}</span>
                          </li>
                          <li className='list-group-item'>
                            Name
                            <span className='pull-right'>{name}</span>
                          </li>
                          {description && (
                            <li className='list-group-item'>
                              Description
                              <span className='pull-right'>{description}</span>
                            </li>
                          )}
                          <li className='list-group-item'>
                            Store Status
                            <span className='pull-right'>
                              {isEnabled ? "Enabled" : "Disabled"}
                            </span>
                          </li>
                          <li className='list-group-item'>
                            Store Verification Status
                            <span className='pull-right'>
                              {isApproved ? "Approved" : "Pending"}
                            </span>
                          </li>
                          <li className='list-group-item'>
                            Merchant
                            <span className='pull-right'>{merchant.name}</span>
                          </li>
                          {palengke && (
                            <li className='list-group-item'>
                              Palengke
                              <span className='pull-right'>
                                {palengke?.name}
                              </span>
                            </li>
                          )}
                          {isApproved && (
                            <>
                              <li className='list-group-item'>
                                Approved By
                                <span className='pull-right'>
                                  {approvedBy?.name}
                                </span>
                              </li>
                              <li className='list-group-item'>
                                Approved At
                                <span className='pull-right'>
                                  {formatIsoDate(approvedAt)}
                                </span>
                              </li>
                            </>
                          )}
                          <li className='list-group-item'>
                            Date Registered
                            <span className='pull-right'>
                              {formatIsoDate(createdAt)}
                            </span>
                          </li>
                          {!isApproved && (
                            <li className='list-group-item'>
                              <button
                                className='btn btn-success btn-md center-block '
                                type='button'
                                data-toggle='modal'
                                data-target='#modal-approve-store'
                              >
                                <i className='fa fa-check'></i> Approve Store
                              </button>
                            </li>
                          )}
                          {isApproved && !isEnabled && (
                            <li className='list-group-item'>
                              <button
                                className='btn btn-success btn-md center-block '
                                type='button'
                                data-toggle='modal'
                                data-target='#modal-enable-store'
                              >
                                <i className='fa fa-check'></i> Enable Store
                              </button>
                            </li>
                          )}

                          {isApproved && isEnabled && (
                            <li className='list-group-item'>
                              <button
                                className='btn btn-danger btn-md center-block'
                                type='button'
                                data-toggle='modal'
                                data-target='#modal-disable-store'
                              >
                                <i className='fa fa-remove'></i> Disable Store
                              </button>
                            </li>
                          )}
                        </ul>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainContent>

      <Modal id='modal-approve-store'>
        <ModalHeader title='Store Approval' />
        <ModalBody>
          <p>Do you want to approve {name} store?</p>
        </ModalBody>
        <ModalFooter>
          <button
            type='button'
            className='btn btn-success'
            data-dismiss='modal'
            onClick={() => handleApproveStore()}
          >
            Yes
          </button>

          <button
            type='button'
            className='btn btn-danger '
            data-dismiss='modal'
            ref={approveStoreRef}
          >
            Cancel
          </button>
        </ModalFooter>
      </Modal>

      <Modal id='modal-enable-store'>
        <ModalHeader title='Store Approval' />
        <ModalBody>
          <p>Do you want to enable {name} store?</p>
        </ModalBody>
        <ModalFooter>
          <button
            type='button'
            className='btn btn-success'
            data-dismiss='modal'
            onClick={() => handleEnableStore()}
          >
            Yes
          </button>

          <button
            type='button'
            className='btn btn-danger '
            data-dismiss='modal'
            ref={enableStoreRef}
          >
            Cancel
          </button>
        </ModalFooter>
      </Modal>

      <Modal id='modal-disable-store'>
        <ModalHeader title='Store Approval' />
        <ModalBody>
          <p>Do you want to disable {name} store?</p>
        </ModalBody>
        <ModalFooter>
          <button
            type='button'
            className='btn btn-success'
            data-dismiss='modal'
            onClick={() => handleDisableStore()}
          >
            Yes
          </button>

          <button
            type='button'
            className='btn btn-danger '
            data-dismiss='modal'
            ref={disableStoreRef}
          >
            Cancel
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ShopperGroupDetailPage;
